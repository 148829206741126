.partner-container {
  width: 80%;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;

  .title {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #28afe4;
  }

  .partner-carousel {
    display: flex;
    overflow-x: hidden;
    width: 100%;
    gap: 20px;
    background-color: #ceedf9;
    height: 100px;
    align-items: center;
  }

  .partner-item {
    flex: 0 0 auto;
    max-width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 30px;
    transition: all 0.3s ease;

    img {
      max-height: 70%;
      max-width: 200px;
      width: auto;
      height: 100px;
      object-fit: contain;
    }
  }
}


@media (max-width: 575.98px) {
  .partner-container {
    // display: none;
    .title{
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .partner-item {
      img {
        max-height: 100%;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .partner-item {
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .partner-item {
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .partner-item {
  }
}

@media (min-width: 1200px) {
  .partner-item {
  }
}
