.footer-container {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #28afe4;
  color: white;
  font-size: 17px;
  font-style: italic;
}

@media (max-width: 575.98px) {
  .footer-container {
    display: none;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .footer-container {
    // display: none;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199.98px) {
  .footer-container {
    // display: none;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1439.98px) {
  .footer-container {
    // display: none;
  }
}

// Extra extra large devices (larger desktops, 1400px and up)
