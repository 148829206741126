.instructor-container {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  .title {
    margin-top: 100px;
    color: #28afe4;
  }

  .instructor-content {
    width: 80%;
    height: 70%;
    // background-color: red;
    padding: 10px 0;
    gap: 150px;

    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;

    .instructor-card {
      width: 30%;
      height: 90%;
      border-radius: 10px;
      // padding: 20px 0;

      background-color: #ceedf9;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .avatar {
        width: 300px;
        height: 300px;
        border-radius: 50%;
        border: solid 10px #28afe4;
        object-fit: cover;
      }

      .instructor-name {
      }

      .instructor-desc {
        font-size: 20px;
        font-weight: 400;
      }

      .btn-flow {
        width: 70%;
        height: 50px;
        font-size: 20px;
        border-radius: 5px;
        margin-top: 30px;

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #28afe4;
        color: white;
        text-decoration: none;
        font-weight: bold;
      }
    }
    .instructor-infor {
      width: 45%;
      height: 90%;
      border-radius: 10px;
      padding: 30px;

      box-sizing: border-box;
      background-color: #7f9aa533;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;

      .infor-item {
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 20px;

        .icon {
          width: 30px;
          height: 30px;
          fill: #28afe4;
        }
        .item-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          .infor-title {
            margin: 0;
            font-size: 25px;
            font-weight: bold;
            color: #28afe4;
          }
          .infor-desc {
            margin: 0;
            margin-top: 20px;
            li {
              margin-bottom: 20px;
              font-size: 18px;
              text-align: start;
            }
          }
        }
      }
    }
  }

  .background {
    // background-color: BLUE;
    z-index: -1;
  }
}

@media (max-width: 575.98px) {
  .instructor-container {
    min-height: 750px;
    // padding: 10px;
    padding-top: 50px;
    .title {
      font-size: 25px;
      margin: 0;
    }
    .instructor-content {
      flex-direction: column;
      justify-content: start;
      align-items: start;
      height: 100%;
      width: 100%;
      gap: 10px;

      .instructor-card {
        height: auto;
        width: 100%;
        background-color: transparent;

        .avatar {
          width: 100px;
          height: 100px;
          border: solid 2px #28afe4;
        }

        .instructor-name {
          font-size: 20px;
          margin: 5px;
        }
        .instructor-desc {
          font-size: 15px;
        }
        .btn-flow {
          height: 40px;
          width: 70%;
          font-size: 15px;
          margin-top: 10px;
        }
      }

      .instructor-infor {
        width: 100%;
        justify-content: space-around;
        padding: 10px;
        padding-bottom: 0px;
        background-color: transparent;
        box-sizing: border-box;

        .infor-item {
          background-color: #7f9aa534;
          width: 100%;
          padding: 10px;
          box-sizing: border-box;
          .icon {
            width: 15px;
            height: 15px;
          }
          .item-content {
            width: 100%;
            .infor-title {
              font-size: 17px;
              margin-top: 10px;
              margin-bottom: 10px;
            }
            .infor-desc {
              width: 100%;
              margin: 0;
              padding: 0;
              li {
                width: 100%;
                font-size: 14px;
                margin-bottom: 5px;
              }
            }
          }
        }
      }

      .instructor-infor:nth-child(2),
      .instructor-infor:nth-child(3) {
        .infor-item {
          .icon {
            width: 30px;
            height: 20px;
            margin-top: 10px;
            margin-left: 10px;
          }
        }
      }
    }

    .background {
      display: none;

      .ellipse {
        width: 200px;
        height: 200px;
        filter: blur(100px);
      }
      .purple {
        top: 100px;
      }

      .yellow {
        top: 50%;
        right: 10%;
        left: auto;
      }

      .rectangle {
        display: none;
      }
    }
    // display: none;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .instructor-container {
    // display: none;

    .title {
      font-size: 30px;
      margin-top: 100px;
    }

    .instructor-content {
      width: 100%;
      align-items: center;
      justify-content: start;
      gap: 0;
      flex-direction: column;

      .instructor-card {
        background-color: transparent;
        width: 80%;
        .avatar {
          width: 150px;
          height: 150px;
          border: 5px solid #28afe4;
        }

        .instructor-name {
          font-size: 25px;
          margin: 10px 0;
        }

        .instructor-desc {
          font-size: 15px;
        }

        .btn-flow {
          width: 200px;
          height: 40px;
          font-size: 15px;
          margin-top: 10px;
        }
      }
      .instructor-infor {
        margin-top: 10px;
        width: 90%;

        .infor-item {
          .icon {
            width: 20px;
            height: 20px;
            margin-top: 5px;
          }

          .item-content {
            .infor-title {
              font-size: 20px;
              // margin-top: 10px;
              margin-bottom: 10px;
            }
            .infor-desc {
              margin-top: 0;
              li {
                font-size: 15px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    .background {
      .yellow {
        filter: blur(200px);
      }
      .purple {
        filter: blur(200px);
      }
      .rectangle {
        display: none;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199.98px) {
  .instructor-container {
    .title {
      font-size: 27px;
    }
    .instructor-content {
      width: 90%;
      gap: 0;
      align-items: start;

      .instructor-card {
        min-height: 500px;
        padding: 50px 0 50px 0;
        width: 40%;
        margin-right: 50px;

        .avatar {
          width: 200px;
          height: 200px;
        }

        .instructor-name {
          font-size: 20px;
        }

        .instructor-desc {
          font-size: 15px;
        }

        .btn-flow {
          width: 150px;
          height: 40px;
          font-size: 14px;
          margin-top: 20px;
        }
      }

      .instructor-infor {
        width: 50%;
        min-height: 500px;

        .infor-item {
          .icon {
            width: 20px;
            height: 20px;
          }
          .item-content {
            .infor-title {
              font-size: 17px;
            }
            .infor-desc {
              margin-top: 5px;
              li {
                font-size: 15px;
                margin-bottom: 5px;
              }
            }
          }
        }

        .infor-item:nth-child(2),
        .infor-item:nth-child(3) {
          .icon {
            width: 30px;
            height: 20px;
          }

          margin-top: 20px;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1439.98px) {
  .instructor-container {
    // display: none;

    .title {
      font-size: 30px;
    }

    .instructor-content {
      gap: 0;
      justify-content: space-around;
      align-items: start;
      .instructor-card {
        width: 370px;
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .avatar {
          width: 250px;
          height: 250px;
          border: #28afe4 5px solid;
        }

        .instructor-name {
          margin: 0;
        }
        .btn-flow {
          margin: 0;
        }
      }

      .instructor-infor {
        width: 550px;
        height: 550px;

        .infor-item {
          .icon {
            width: 30px;
            height: 30px;
          }

          .item-content {
            .infor-title {
            }

            .infor-desc {
              li {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}

// Extra extra large devices (larger desktops, 1400px and up)
@media (min-width: 1440px) and (max-width: 1999.98px) {
  .instructor-container {
    .title {
      font-size: 35px;
    }

    .instructor-content {
      gap: 0;
      justify-content: space-around;
      align-items: start;
      .instructor-card {
        width: 450px;
        height: 650px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .avatar {
          width: 250px;
          height: 250px;
          border: #28afe4 5px solid;
        }

        .instructor-name {
          margin: 0;
        }
        .btn-flow {
          margin: 0;
        }
      }

      .instructor-infor {
        width: 650px;
        height: 650px;

        .infor-item {
          .icon {
            width: 30px;
            height: 30px;
          }

          .item-content {
            .infor-title {
            }

            .infor-desc {
              li {
                // font-size: 15px;
              }
            }
          }
        }

        .infor-item:first-child {
          .icon {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
@media (min-width: 2000px) {
  // display: none;
  .instructor-container {
    max-width: 1980px;
    .title {
      font-size: 35px;
    }

    .instructor-content {
      gap: 0;
      justify-content: space-around;
      align-items: start;
      .instructor-card {
        width: 450px;
        height: 650px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .avatar {
          width: 250px;
          height: 250px;
          border: #28afe4 5px solid;
        }

        .instructor-name {
          margin: 0;
        }
        .btn-flow {
          margin: 0;
        }
      }

      .instructor-infor {
        width: 650px;
        height: 650px;

        .infor-item {
          .icon {
            width: 30px;
            height: 30px;
          }

          .item-content {
            .infor-title {
            }

            .infor-desc {
              li {
                // font-size: 15px;
              }
            }
          }
        }

        .infor-item:first-child {
          .icon {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
