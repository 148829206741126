.homeContainer {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 200px;

  .headerMobile {
    display: none;
  }

  .heroSection {
    width: 30%;
    margin-left: 250px;
    margin-top: 70px;

    position: absolute;
    z-index: 2;

    .title {
      font-size: 67px;
      font-weight: 700;
      margin-top: 0;
      background: linear-gradient(
        90deg,
        rgba(15, 19, 130, 1) 0%,
        rgba(114, 20, 158, 1) 22%,
        rgba(0, 149, 207, 1) 52%,
        rgba(87, 105, 205, 1) 80%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      letter-spacing: 5px;
      text-align: left;
      margin-bottom: 10px;
    }

    .badge {
      width: 100px;
      height: 40px;
      background: linear-gradient(
        90deg,
        rgba(238, 176, 84, 1) 0%,
        rgba(207, 108, 53, 1) 100%
      );
      position: absolute;
      top: 115px;
      right: 16%;
      border-radius: 1000px;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: white;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: 2px;
      }
    }

    .highlightTextContainer {
      width: 85%;
      text-align: justify;
      color: #777777;
      font-size: 17px;
      font-weight: 500;
      line-height: 30px;
    }
    .thumbnailMobile {
      display: none;
      width: 500px;
      height: 300px;
      position: relative;

      img {
        border-radius: 20px;
        width: 500px;
        height: 300px;
      }

      .btnPlay {
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 20px;

        .rectangle {
          position: absolute;
          top: calc(50% - 25px);
          left: calc(50% - 25px);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #454545;
          opacity: 0.76;
        }
      }
      &:hover {
        .btnPlay {
          background-color: rgba(128, 124, 124, 0.24);

          .rectangle {
            opacity: 0.9;
          }
        }
      }
      .bg-thumbnail {
        background-color: #ace5fb;
        position: absolute;
        width: 700px;
        height: 430px;
        right: 40px;
        top: 40px;
        opacity: 0.36;
        z-index: -1;
        border-radius: 20px;
      }
    }

    .offer {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .offerItem {
        display: flex;
        align-items: center;
        gap: 20px;

        .ellipse {
          width: 15px;
          height: 15px;
          border-radius: 20px;
        }

        .highlightTextContainer {
          color: #777777;
          font-size: 15px;
          font-weight: 500;
        }
      }

      .studientCount {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
    .studientCount {
      .ellipse {
        width: 15px;
        height: 15px;
        border-radius: 20px;
        background-color: #ff0000;
      }
      p {
        color: #777777;
        font-size: 15px;
        font-weight: 500;
      }
    }

    .btnContainer {
      width: 85%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .btnEnroll,
      .btnShare {
        text-decoration: none;
        height: 50px;
        width: 150px;
        display: flex;
        cursor: pointer;
      }

      .btnEnroll {
        background-color: #28afe4;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        border: none;

        p {
          color: white;
          font-size: 17px;
          font-weight: 600;
        }
      }
    }
  }

  .thumbnail {
    top: 300px;
    width: 700px;
    height: 430px;
    right: 270px;
    position: absolute;
    z-index: 1;
    cursor: pointer;

    .videoContainer{
      width: 700px;
      height: 430px;
      overflow: hidden;
      border-radius: 20px;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;

      video{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    img {
      z-index: 100;
      width: 700px;
      height: 430px;
      object-fit: cover;
      border-radius: 20px;
      transition: opacity 0.5s ease, filter 0.5s ease;
    }

    .btnPlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 20px;
      transition: opacity 0.5s ease;

      .rectangle {
        position: absolute;
        top: calc(50% - 35px);
        left: calc(50% - 35px);
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: #454545;
        opacity: 0.76;
      }
    }

    &:hover {
      .btnPlay {
        background-color: rgba(128, 124, 124, 0.24);

        .rectangle {
          opacity: 0.9;
        }
      }
    }
    .bg-thumbnail {
      position: absolute;
      width: 700px;
      height: 430px;
      right: 100px;
      top: 100px;
      opacity: 0.36;
      z-index: -1;
      border-radius: 20px;
      background-color: #31999c;
    }
    &.video-playing {
      img {
        opacity: 0;
        filter: blur(10px);
      }
  
      .btnPlay {
        opacity: 0;
      }
  
      .videoContainer {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  .footer {
    width: 1000px;
    height: 200px;
    position: absolute;
    bottom: 80px;
    left: calc(50% - 500px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    // display: none;

    .backgroundFooter {
      width: 100%;
      top: 0;
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 0;
      gap: 20px;

      .rectangle {
        width: 100%;
        height: 200px;
        background-color: #ace5fb;
        filter: blur(130px);
      }
    }

    .corporateAdvertising {
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // gap: 20px;
      z-index: 10;
      // background-color: #454545;

      .item {
        width: 200px;
        height: 60px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 10px;
        box-shadow: -4px 10px 4px rgba(0, 0, 0, 0.25);

        .icon {
          width: 30px;
          height: 30px;
          fill: white;
        }

        p {
          width: calc(100% - 80px);
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          margin: 0;
          color: #777777;
          font-size: 17px;
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .homeContainer {
    // height: auto;
    min-height: 750px;
    max-height: 760px;

    padding: 0;
    .headerMobile {
      display: flex;
      height: 50px;

      .logo {
        height: 100%;
        margin-left: 30px;
      }
    }

    .heroSection {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      margin: 0;
      width: 100%;
      height: calc(100% - 50px);

      .title {
        letter-spacing: 2px;
        font-size: 30px;
        margin: 0;
        margin-top: 20px;
      }
      .badge {
        display: none;
      }
      .highlightTextContainer {
        // display: none;0
        font-size: 15px;
        width: 70%;
        text-align: center;
      }
      .thumbnailMobile {
        width: 300px;
        height: 200px;
        display: block;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .videoContainer{
          width: 300px;
          height: 200px;
          position: absolute;
          overflow: hidden;

          video{
            display: block;
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
        img {
          width: 300px;
          height: 200px;
          position: absolute;
        }
        .bg-thumbnail {
          display: none;
        }
        .btnPlay {
          position: absolute;
          z-index: 10;
          left: auto;
        }
      }
      .offer {
        // position: absolute;
        width: 75%;
        justify-self: flex-start;
        margin-top: 0;
        margin-top: 10px;
        // bottom: -350px;

        .offerItem,
        .studientCount {
          .highlightTextContainer {
            width: calc(100% - 60px);
            margin: 5px;
            text-align: left;
            font-size: 14px;
          }
        }
      }
      .btnContainer {
        // display: none;
        justify-content: space-around;
        width: 70%;

        .btnShare,
        .btnEnroll {
          text-decoration: none;
          width: 120px;
          p {
            font-size: 14px;
            margin: 5px;
          }

          .icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .thumbnail {
      display: none;
      // position: relative;
    }

    .footer {
      display: none;
    }
    .background {
      z-index: -1;
      // display: none;
      .ellipse {
        width: 200px;
        height: 200px;
        filter: blur(80px);
      }

      .yellow {
        top: 30%;
        right: 0;
      }

      .purple {
        top: 0;
        left: 0;
      }

      .rectangle {
        width: 200px;
        height: 100px;
        top: auto;
        filter: blur(70px);
      }
      .bg {
        bottom: 20%;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .homeContainer {
    // display: none;
    padding-top: 0;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .heroSection {
      box-sizing: border-box;
      margin: 0;
      position: relative;
      width: calc(100% - 100px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        font-size: 30px;
        width: 100%;
        text-align: center;
      }

      .thumbnailMobile {
        display: flex;
      }

      .badge {
        height: 30px;
        width: 80px;
        display: none;
        top: 45px;
        left: 170px;
      }

      .highlightTextContainer {
        // display: none;
        width: 50%;
        font-size: 14px;
      }
      .thumbnailMobile {
        img {
          width: 500px;
          height: 300px;
        }
        
        .videoContainer{
          width: 500px;
          height: 300px;
          position: absolute;
          overflow: hidden;

          video{
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .btnPlay {
          width: 100%;
          height: 100%;
          position: absolute;

          .rectangle {
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% - 25px);
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #454545;
            opacity: 0.76;
          }
        }
        .bg-thumbnail {
          width: 100%;
          height: 100%;
          top: 20px;
          right: 20px;
        }
      }
      .offer {
        // align-self: start;
        width: auto;
        margin-top: 30px;

        .offerItem {
          p {
            width: 90%;
            margin: 0;
          }
        }
      }

      .studientCount {
        p {
          width: 90%;
          margin: 0;
        }
      }
    }
    .thumbnail {
      display: none;
    }

    .footer {
      // flex: 2;
      display: none;
    }

    .background {
      // display: none;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199.98px) {
  .homeContainer {
    // display: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 50px 10%;

    .heroSection {
      position: relative;
      width: 50%;
      margin: 0;
      flex: 1;
      margin-top: 30px;

      .title {
        width: 310px;
        font-size: 40px;
      }

      .badge {
        height: 30px;
        width: 80px;
        top: 65px;
        left: 230px;
      }

      .highlightTextContainer {
        width: 310px;
        font-size: 14px;
      }

      .thumbnailMobile {
        display: none;
      }

      .offer {
        .offerItem,
        .studientCount {
          .highlightTextContainer {
            font-size: 14px;
            margin: 0;
          }
        }
      }

      .btnContainer {
        width: 310px;
        .btnEnroll {
          height: 40px;
          width: 120px;

          p {
            font-size: 13px;
            font-weight: bold;
          }
        }

        div {
          .btnShare {
            height: 41px;
            width: 120px;
            .icon {
              width: 20px;
              height: 20px;
            }
            p {
              font-size: 13px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .thumbnail {
      // flex: 1;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      width: 40%;
      height: 350px;
      margin-top: 50px;
      img {
        width: 100%;
        height: 90%;
      }
      
      .videoContainer{
        width: 100%;
        height: 100%;
        .video{
          width: 100%;
          height: 100%;
        }
      }

      .btnPlay {
        width: 100%x;
        height: 90%;
      }

      .bg-thumbnail {
        width: 100%;
        height: 90%;
        right: 20px;
        top: 20px;
      }
    }

    .footer {
      // display: none;
      box-sizing: border-box;
      width: 80%;
      height: 100px;
      margin-top: -50px;
      min-width: 700px;
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      z-index: -1;

      .corporateAdvertising {
        .item {
          flex-direction: column;
          width: 20%;
          height: 50px;

          .icon {
            width: 20px;
            height: 20px;
          }

          p {
            width: 100%;
            height: auto;
            font-size: 12px;
          }
        }
      }

      .backgroundFooter {
        width: 100%;
        justify-content: space-evenly;
        top: auto;
        .rectangle {
          // width: 20%;
          height: 100px;
          filter: blur(80px);
        }
      }
    }

    .background {
      .ellipse {
        width: 200px;
        height: 200px;
        filter: blur(100px);
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .homeContainer {
    min-height: 600px;
    max-height: 600px;
    display: flex;
    flex-wrap: wrap;
    // display: none;
    justify-content: space-around;
    padding-top: 150px;

    .heroSection {
      padding: 0 50px 50px 50px;
      width: 500px;
      margin: 0;
      position: relative;
      box-sizing: border-box;
      margin-top: 20px;
      .thumbnailMobile {
        display: none;
      }

      .badge {
        position: absolute;
        top: 80px;
        right: 80px;
      }

      .title {
        min-width: 500px;
        max-width: 500px;
        font-size: 50px;
      }

      .highlightTextContainer {
        width: 370px;
        font-size: 15px;
      }

      .offer {
        .offerItem {
          .highlightTextContainer {
            font-size: 15px;
            margin: 0;
          }
        }
        .studientCount {
          .highlightTextContainer {
            font-size: 15px;
            margin: 0;
          }
        }
      }
    }
    .thumbnail {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      width: 48%;

      img {
        width: 100%;
      }

      .videoContainer{
        width: 100%;
        height: 100%;
        .video{
          width: 100%;
          height: 100%;
        }
      }

      .btnPlay {
        z-index: 1000;
        position: absolute;
      }

      .bg-thumbnail {
        position: absolute;
        width: 100%;
        top: 40px;
        right: 40px;
      }
    }

    .footer {
      // display: none;
      box-sizing: border-box;
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
    }
  }
}

// Extra extra large devices (larger desktops, 1400px and up)
@media (min-width: 1500px) and (max-width: 1999.98px) {
  .homeContainer {
    // display: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-height: 750px;
    max-height: 750px;
    padding-top: 70px;

    .heroSection {
      width: 500px;
      margin: 0;
      position: relative;
      margin-top: 80px;

      .highlightTextContainer {
        width: 95%;
      }
      .badge {
        right: 20px;
      }

      .btnContainer {
        width: 95%;
      }
    }

    .thumbnail {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      margin-top: 80px;

      width: 700px;
      height: 430px;

      .bg-thumbnail {
        top: 50px;
        right: 50px;
      }
    }

    .footer {
      position: relative;
      bottom: auto;
      left: auto;
    }
  }
}

@media (min-width: 2000px) {
  .homeContainer {
    // display: none;
    max-width: 1980px;
    height: 1040px;
    min-height: 850px;
    max-height: 850px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .heroSection {
      width: 500px;
      margin: 0;
      position: relative;
      margin-top: 00px;

      .highlightTextContainer {
        width: 95%;
      }
      .badge {
        right: 20px;
      }

      .btnContainer {
        width: 95%;
      }
    }

    .thumbnail {
      position: relative;
      top: auto;
      left: auto;
      right: auto;

      width: 700px;
      height: 430px;

      .bg-thumbnail {
        top: 50px;
        right: 50px;
      }
    }

    .footer {
      position: relative;
      bottom: auto;
      left: auto;
      margin-top: -100px;
    }
  }
}
