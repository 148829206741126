.otherCourses-container {
  width: 100%;
  height: 100%;
  // background-color: red;s
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-top: 70px;
  position: relative;
  .title {
    margin-top: 20px;
    margin-bottom: 0;
    color: #28afe4;
    width: 70%;
  }

  .otherCourses-wrapper {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ceedf9;
    width: 80%;
    border-radius: 10px;
    // height: 60%;

    .navigation-buttons {
      z-index: 100;
      .prev,
      .next {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        position: absolute;
        background-color: #e7f1f5;
        cursor: pointer;
        box-shadow: -4px 10px 4px rgba(0, 0, 0, 0.25);

        .icon {
          width: 30px;
          height: 30px;
        }
      }

      .prev {
        left: 160px;
        top: 50%;
      }
      .next {
        top: 50%;
        right: 160px;
      }
    }
    .wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .course-card {
        text-decoration: none;
        background-color: #ffffff;
        width: 350px;
        height: 400px;
        padding: 20px;
        border-radius: 20px;
        margin-top: 40px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        position: relative;
        .thumbnail {
          width: 100%;
          height: 40%;
          object-fit: cover;
          border-radius: 0 0 25px 0;
        }
        .badge {
          width: 130px;
          height: 40px;
          border-radius: 50px;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: calc(40% - 44px);
          right: 20px;
          background-color: rgba(255, 255, 255, 0.918);
          border: 4px solid #28afe4;
          p {
            text-decoration: none;
            font-size: 17px;
            font-weight: bold;
            color: #28afe4;
          }
        }
        .title {
          font-size: 20px;
          margin-top: 5px;
          text-align: start;
          height: 60px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: start;
          text-align: left;

          // background-color: red;
        }
        .desc {
          width: 100%;
          height: 400px;
          margin: 0;
          font-size: 16px;
          margin-bottom: 10px;
          color: #7d7d7d;

          overflow: hidden;
          text-align: justify;
          text-overflow: ellipsis;
          display: -webkit-box;
          display: -moz-box;
          display: box;
          -webkit-line-clamp: 2;
          -moz-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          -moz-box-orient: vertical;
          box-orient: vertical;
          max-height: 2.8em;
          line-height: 1.4em;
        }

        .instructor,
        .duration,
        .studenEnrolled {
          display: flex;
          gap: 20px;

          .icon {
            width: 15px;
            height: 15px;
            fill: #28afe4;
          }

          p {
            color: #7d7d7d;
            font-size: 14px;
            margin: 0;

            span {
              color: black;
              font-weight: 500;
            }
          }
        }

        .instructor {
          width: 100%;
          text-align: start;
          p {
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .duration {
          .icon {
            margin-top: 3px;
          }
        }

        .btn-container {
          width: 100%;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          .btn-enrol-now {
            padding: 10px 50px;
            font-size: 17px;
            text-decoration: none;
            background-color: #28afe4;
            border-radius: 10px;
            font-weight: bold;
            color: white;
          }
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      .dot {
        width: 15px;
        height: 15px;
        background-color: #a6a6a6;
        border-radius: 50%;
        margin: 0 5px;
        cursor: pointer;

        &.active {
          background-color: #28afe4;
        }
      }
    }
  }
  .background {
    z-index: -1;
  }
}

@media (max-width: 575.98px) {
  .otherCourses-container {
    justify-content: space-between;
    padding: 0;
    padding-top: 20px;
    min-height: 700px;
    max-height: 700px;
    min-width: 400px;
    // display: none;

    .title {
      font-size: 20px;
      margin: 0;
    }

    .otherCourses-wrapper {
      margin: 0;
      width: 100%;
      height: auto;
      background-color: transparent;

      .navigation-buttons {
        width: 100%;
        height: 100%;
        background-color: red;
        z-index: 10;

        .next {
          top: 35%;
          right: 5%;
          width: 50px;
          height: 50px;

          .icon {
            width: 20px;
            height: 20px;
            margin-left: 5px;
          }
        }
        .prev {
          top: 35%;
          left: 5%;
          height: 50px;
          width: 50px;
          .icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
      }

      .wrapper {
        .course-card {
          margin-top: 0;
          width: 250px;
          height: 350px;

          .badge {
            width: 100px;
            height: 30px;
            top: 125px;

            p {
              font-size: 12px;
            }
          }

          .title {
            font-size: 15px;
            height: 40px;
          }

          .desc {
            font-size: 12px;
          }

          .instructor,
          .duration,
          .studenEnrolled {
            gap: 10px;

            .icon {
              width: 10px;
              height: 12px;
            }

            p {
              font-size: 12px;
            }
          }

          .btn-container {
            margin-top: 10px;
            .btn-enrol-now {
              padding: 5px 20px;
              font-size: 12px;
              border-radius: 5px;
            }
          }
        }
      }
    }

    .partner-container {
      width: 90%;
      // display: none;
      .partner-carousel {
        // max-width: 200px;
        gap: 0;
        display: flex;
        align-items: center;
        .partner-item {
          min-width: auto;
          height: 50px;
          img {
            height: 50px;
          }
        }
      }
    }

    .background {
      display: none;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 749.98px) {
  .otherCourses-container {
    // display: none;
    min-height: 910px;
    max-height: 930px;
    .title {
      font-size: 30px;
    }
    .otherCourses-wrapper {
      .navigation-buttons {
        z-index: 100;

        .next {
          right: 70px;
        }

        .prev {
          left: 70px;
        }
      }
      .wrapper {
        .course-card {
          width: 300px;
          height: 350px;
        }
      }
    }

    .background {
      .ellipse {
      }
      & .yellow {
        top: 0;
        filter: blur(300px);
      }

      & .purple {
        top: 30%;
      }

      .bg {
        top: auto;
        bottom: 0;
        // filter: blur(200px);
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 750px) and (max-width: 1199.98px) {
  .otherCourses-container {
    min-height: 910px;
    max-height: 930px;
    .otherCourses-wrapper {
      .navigation-buttons {
        z-index: 100;

        .prev {
          left: 25px;
        }

        .next {
          right: 25px;
        }
      }
    }

    .otherCourses-wrapper {
      height: auto;
      width: 90%;

      .course-card {
        width: 45%;
        max-width: 280px;
      }
    }

    .partner-container {
      width: 90%;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .otherCourses-container {
    min-height: 910px;
    max-height: 930px;
    // display: none;
    .otherCourses-wrapper {
      width: 90%;
      .navigation-buttons {
        z-index: 100;

        .prev {
          left: 25px;
        }

        .next {
          right: 25px;
        }
      }

      .wrapper {
        .course-card {
          width: 300px;
          height: 350px;
        }
      }
    }

    .partner-container {
      width: 90%;
    }
  }
}

// Extra extra large devices (larger desktops, 1400px and up)
@media (min-width: 1500px) and (max-width: 1699.98px) {
  .otherCourses-container {
    // display: none;
    min-height: 910px;
    max-height: 930px;
    .otherCourses-wrapper {
      .navigation-buttons {
        z-index: 100;
      }
      .wrapper {
        width: 90%;
        .course-card {
          width: 300px;
        }
      }
    }
  }
}
@media (min-width: 1700px) and (max-width: 1999.98px) {
  .otherCourses-container {
    // display: none;
    min-height: 910px;
    max-height: 930px;
  }
}

@media (min-width: 2000px) {
  .otherCourses-container {
    // display: none;
    max-width: 1980px;
    max-height: 1000px;
    min-height: 910px;
    margin: auto;
  }
}
