.App {
  text-align: center;
  margin: 0;
  padding: 0;
  align-items: center;
  /* width: 100vw; */
  height: 100vh;
  /* min-height: 100vh; */
}

section {
  height: calc(100% - 70px);
  min-height: calc(100% - 70px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

header {
  height: 70px;
}
/* Cho Firefox */
/* * {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
} */

/* Cho WebKit browsers */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #aaafaf;
}

*::-webkit-scrollbar-thumb:hover {
  background: #cecece;
}

@media (max-width: 575.98px) {
  header {
    height: 0px;
  }

  section {
    height: auto;
    min-height: auto;
    position: relative;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  section {
    height: auto;
    min-height: auto;
    position: relative;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  section {
    height: auto;
    min-height: auto;
    position: relative;
  }
}

@media (min-width: 1200px) and (max-width: 1439.98px) {
  section {
    height: auto;
    min-height: auto;
    position: relative;
  }
}

@media (min-width: 1440px) and (max-width: 1999.98px) {
  section {
    height: auto;
    min-height: auto;
    position: relative;
  }
}
@media (min-width: 2000px) {
  section {
    height: auto;
    min-height: auto;
    position: relative;
  }
}
