.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  .ellipse {
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    filter: blur(150px);
  }

  .yellow {
    background-color: #faffaf;
    top: 30%;
    left: 30%;
  }

  .purple {
    top: 50px;
    left: 100px;
    background-color: #b39ddb;
  }
  .rectangle {
    background-color: #ace5fb;
    position: absolute;
  }

  .bg {
    width: 300px;
    height: 300px;
    border-radius: 0 300px 0 0;
    right: 100px;
    top: 100px;
    filter: blur(150px);
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199.98px) {
  .background {
    // display: none;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1439.98px) {
  .background {
    // display: none;
  }
}

// Extra extra large devices (larger desktops, 1400px and up)
@media (min-width: 1440px) {
}
