.testimonials-container {
  min-height: 100%;
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 50px;
  position: relative;
  user-select: none;
  .title {
    color: #28afe4;
  }
  .testimonials-wrapper {
    width: 80%;
    height: calc(100% - 200px);
    // margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-wrap: wrap;
    position: relative;
    padding-bottom: 50px;
    gap: 50px;

    .testimorial-card {
      width: 450px;
      height: 200px;
      cursor: pointer;

      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: space-between;
      background-color: #ffffff;
      border-radius: 15px 15px 17px 17px;
      transition: all 1s ease-in-out;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      //;
      .userInfor {
        height: 150px;
        width: 450px;
        border-radius: 0 0 15px 15px;
        position: absolute;
        bottom: 0;
        // box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // filter: drop-shadow(0px 10px 4px rgba(0, 0, 0, 0.25));

        background-color: #ffffff;

        .avatar {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          margin-top: -10px;
        }

        .userName {
          font-size: 23px;
          margin-top: 5px;
          margin-bottom: 5px;
          font-weight: bold;
          color: #292929;
        }

        .job {
          margin: 0;
          font-size: 15px;
          color: #707070;
          font-weight: 400;
        }
      }

      // .line {
      //   position: absolute;
      //   bottom: 0;
      //   right: calc(50% - 200px);

      //   background: #2ecc71;
      //   clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
      //   width: 400px;
      //   height: 200px;
      // }

      .content {
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        border-radius: 20px;
        height: 100%;
        user-select: none;
        // transition: height 0.1s ease-in-out;

        .desc {
          font-weight: bold;
          margin-top: 0;
          font-size: 17px;
          margin-top: 10px;
          font-style: italic;
          padding: 0 10px;
        }

        p {
          height: 100%;
          opacity: 0;
          color: #000;
          margin: 0;
          padding: 20px;
          font-size: 15px;
          text-align: justify;
          font-style: italic;
          height: 0px;
          box-sizing: border-box;
        }
      }
    }
    .testimorial-card:nth-child(1),
    .testimorial-card:nth-child(2),
    .testimorial-card:nth-child(3) {
      top: 70px;
    }
    .testimorial-card:nth-child(4),
    .testimorial-card:nth-child(5),
    .testimorial-card:nth-child(6) {
      bottom: 70px;
    }
    .testimorial-card:nth-child(2),
    .testimorial-card:nth-child(5) {
      left: calc(50% - 225px);
    }
    .testimorial-card:nth-child(3),
    .testimorial-card:nth-child(6) {
      right: 0;
    }

    .testimorial-card {
      &.active {
        // transform: translateY(150px);
        height: 350px;

        .content {
          p {
            transition: opacity 0.5s ease-out 0.5s;
            opacity: 1;
          }
        }
      }
    }
  }
  .backgroundTestimonials {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    .ellipse {
      position: absolute;
      width: 400px;
      height: 400px;
      border-radius: 50%;
      filter: blur(150px);
    }

    .yellow {
      top: 20%;
      right: 0px;
      background-color: #f9ff9b;
    }

    .purple {
      top: 20%;
      left: 0;
      background-color: #9979d2;
    }
    .blue {
      bottom: 100px;
      left: calc(50% - 200px);
      background-color: #35bcf1;
    }
  }
}

@media (max-width: 575.98px) {
  .testimonials-container {
    // display: none;
    min-width: 385px;
    user-select: none;

    .title {
      font-size: 23px;
      padding: 0 10px;
    }

    .testimonials-wrapper {
      width: 90%;
      height: auto;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;

      .testimorial-card {
        margin-top: 20px;
        position: static;
        width: 100%;
        max-width: 350px;
        // height: 200px;

        .content {
          position: static;

        }

        .userInfor {
          position: static;
          width: 100%;

          .job {
            font-size: 13px;
            margin-bottom: 10px;
            padding: 0 10px;
          }
        }

        &.active {
          height: 430px;
          .content {
            p {
              // display: none;
            }
          }
        }
      }
    }

    .backgroundTestimonials {
      // display: none;

      .ellipse {
        filter: blur(300px);
      }

      .yellow {
        top: 0;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .testimonials-container {
    // display: none;
    justify-content: start;

    .title {
      font-size: 30px;
      margin-bottom: 50px;
    }

    .testimonials-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .testimorial-card {
        position: static;

        .content {
          position: static;
        }

        .userInfor {
          position: static;

          .job {
            margin-bottom: 20px;
          }
        }
      }
    }

    .backgroundTestimonials {
      // display: none;
      .ellipse {
        filter: blur(300px);
      }
      & .yellow {
        top: 0;
      }

      & .purple {
        top: 30%;
      }

      & .blue {
        filter: blur(250px);
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 947.98px) {
  .testimonials-container {
    min-height: 2000px;
    max-height: 2300px;
    // display: none;
    display: flex;
    flex-direction: column;
    justify-content: start;

    .testimonials-wrapper {
      gap: 50px;
      justify-content: center;
      padding-top: 50px;
      .testimorial-card {
        width: 350px;
        height: 250px;
        position: static;

        .content {
          width: 100%;
          position: relative;

          .desc {
            height: 50px;
            width: 80%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }


        }

        .userInfor {
          .job {
            margin-bottom: 20px;
          }
        }

        .userInfor {
          width: 100%;
          position: relative;
        }
        &.active {
          height: 450px;
        }
      }
    }
  }
}
@media (min-width: 948px) and (max-width: 1199.98px) {
  .testimonials-container {
    min-height: 900px;
    max-height: 1200px;
    // display: none;
    display: flex;
    flex-direction: column;
    justify-content: start;

    .testimonials-wrapper {
      gap: 50px;
      justify-content: center;
      padding-top: 50px;
      .testimorial-card {
        width: 350px;
        height: 250px;
        position: static;

        .content {
          width: 100%;
          position: relative;

          .desc {
            height: 50px;
            width: 80%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .userInfor {
          .job {
            margin-bottom: 20px;
          }
        }

        .userInfor {
          width: 100%;
          position: relative;
        }
        &.active {
          height: 450px;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1439.98px) {
  .testimonials-container {
    // display: none;
    min-height: 1050px;
    max-height: 1100px;

    display: flex;
    flex-direction: column;
    justify-content: start;

    .testimonials-wrapper {
      gap: 50px;
      justify-content: center;
      padding-top: 50px;
      .testimorial-card {
        width: 450px;
        height: 250px;
        position: static;

        .content {
          width: 100%;
          position: relative;

          .desc {
            height: 50px;
            width: 80%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .userInfor {
          .job {
            margin-bottom: 20px;
          }
        }

        .userInfor {
          width: 100%;
          position: relative;
        }
        &.active {
          height: 400px;
        }
      }
    }
  }
}

// Extra extra large devices (larger desktops, 1400px and up)
@media (min-width: 1440px) and (max-width: 1749.98px) {
  .testimonials-container {
    // display: none;
    min-height: 1050px;
    max-height: 1100px;

    // min-height: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    .testimonials-wrapper {
      gap: 50px;
      justify-content: center;
      padding-top: 50px;
      .testimorial-card {
        width: 450px;
        height: 250px;
        position: static;

        .content {
          width: 100%;
          position: relative;

          .desc {
            height: 50px;
            width: 80%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .userInfor {
          .job {
            margin-bottom: 20px;
          }
        }

        .userInfor {
          width: 100%;
          position: relative;
        }
        &.active {
          height: 400px;
        }
      }
    }
  }
}
@media (min-width: 1750px) and (max-width: 1999.98px) {
  .testimonials-container {
    // display: none;
    max-height: 950px;
    min-height: 950px;

    height: 100%;
  }
}

@media (min-width: 2000px) {
  .testimonials-container {
    max-height: 950px;
    min-height: 950px;
    height: 100%;
    // display: none;
    max-width: 1980px;
    margin: auto;
  }
}
