.nav {
  .navbar-container {
    height: 70px;
    width: 100%;
    user-select: none;

    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30%;

    a {
      .logo {
        height: 70px;
      }
    }

    .list-menu {
      width: 40%;
      height: 100%;

      display: flex;
      justify-content: space-around;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;

      .menu-item {
        height: 100%;

        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;

        a {
          height: 70%;
          font-size: 16px;

          text-decoration: none;
          font-weight: bold;
          padding: 0px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
        }

        .scroll-active {
          color: white;
          background-color: #28afe4;
        }
      }
    }
  }
  .iconMenu {
    display: none;
    position: fixed;
    z-index: 1000;
    height: 50px;
    width: 50px;
    right: 20px;
    top: 0px;
    fill: #28afe4;
  }
}

@media (max-width: 575.98px) {
  .nav {
    .navbar-container {
      width: 50%;
      height: auto;
      transition: all 0.5s ease;
      right: 0;
      left: auto;
      transform: translateX(110%);
      border-radius: 0px 0px 0px 10px;
      box-shadow: -6px 6px 4px rgba(0, 0, 0, 0.25);
      background-color: #ffffff;

      a {
        display: none;
        .logo {
          // width: 100px;
          display: none;
        }
      }
      .list-menu {
        // display: none;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .menu-item {
          width: 100%;
          height: 70px;

          a {
            width: 100%;
          }
          .scroll-active {
            border-radius: 0px;
          }
        }
      }
    }
    .iconMenu {
      display: block;
    }
    .mobile-menu-open {
      transform: translateX(0%);
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .nav {
    .navbar-container {
      gap: 0;
      .list-menu {
        width: calc(100% - 200px);

        .menu-item {
          a {
            font-size: 13px;
            padding: 0;
            height: auto;
            padding-bottom: 5px;
            border-bottom: solid 2px #ffffff;
          }

          .scroll-active {
            color: #28afe4;
            border-radius: 0px;
            border-bottom: solid 2px #28afe4;
            background-color: transparent;
          }
        }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .nav {
    .navbar-container {
      gap: calc(30% - 200px);
      .list-menu {
        width: 70%;
        .menu-item {
          a {
            height: 40px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .nav {
    .navbar-container {
      gap: calc(40% - 200px);
      .list-menu {
        width: 60%;
        .menu-item {
          a {
            height: 40px;
            font-size: 15px;
          }
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1439.98px) {
  .nav {
    .navbar-container {
      gap: calc(50% - 400px);
      .list-menu {
        width: 50%;
        .menu-item {
          a {
            height: 40px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
@media (min-width: 1440px) and (max-width: 1979.98px) {
}

// Extra extra large devices (larger desktops, 1400px and up)
@media (min-width: 1980px) {
  .nav {
    margin: auto;
    height: 70px;
    background-color: white;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    .navbar-container {
      left: calc(50% - 990px);
      width: 1980px;
    }
  }
}
