.courseContent-container {
  width: 100%;
  height: 100%;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;

  position: relative;

  .title {
    margin-top: 40px;
    margin-bottom: 40px;
    color: #28afe4;
  }
  .time-line-using-for-mobile {
    display: none;
  }
  .fish-born-time-line {
    width: 80%;
    margin-top: 70px;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
    z-index: 10;

    .skeleton {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      .fish-back-born {
        width: calc(100% - 150px - 200px);
        //   border-bottom: 10px dashed #d4d4d4;
        //   border-radius: 50px;
        border-bottom: 10px solid;
        border-image: repeating-linear-gradient(
            to right,
            #dddddd 0,
            #dddddd 40px,
            transparent 40px,
            transparent 80px
          )
          1;
      }
    }

    .content-couse {
      width: calc(100% - 150px - 200px);
      margin-left: 150px;
      padding: 0 5px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 10px;
      .item {
        position: relative;
        .day {
          width: 100%;
          height: 50px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            width: 50px;
            height: 50px;
            // position: absolute;
            // top: 0;
            // left: 0;

            text-align: center;
            border-radius: 50%;
            background-color: #28afe4;
            color: white;
            font-size: 35px;
            font-weight: bold;
            margin: 0;
            margin-left: 25px;
            z-index: 50;
          }
        }

        .upper-content,
        .lower-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .line {
            width: 100px;
            border-bottom: 10px solid #dddddd;
          }
          .desc {
            width: 250px;
            max-width: 90%;
            height: 150px;

            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #dddddd;
            padding: 20px;
            color: rgb(0, 0, 0);
            font-size: 23px;
            font-weight: 700;
            border-radius: 10px;
          }
        }

        .upper-content {
          //   margin-bottom: 25px;
          .line {
            transform: rotate(45deg);
          }
        }
        .lower-content {
          .line {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  .backgroundCouresContent {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    z-index: -1;

    .rectangle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 90%;
      background-color: #d7f4ff;
      filter: blur(100px);
    }

    .ellipse {
      position: absolute;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background: radial-gradient(
        circle at 50% 30%,
        rgba(255, 255, 255, 1) 0%,
        rgba(70, 183, 227, 1) 70%
      );
      overflow: hidden;
      filter: blur(5px);
    }

    .ellipse:nth-child(2) {
      left: 15%;
      top: 30%;
      transform: translateY(-50%);
    }

    .ellipse:nth-child(3) {
      right: 15%;
      top: 35%;
      transform: translateY(-50%);
    }

    .ellipse:nth-child(4) {
      bottom: 40%;
      left: 55%;
      transform: translateX(-50%);
    }

    .ellipse::before {
      content: "";
      position: absolute;
      top: -60%;
      left: -60%;
      right: -60%;
      bottom: -60%;
      background: radial-gradient(
        circle at 50% 80%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 60%
      );
    }
  }
}

@media (max-width: 575.98px) {
  .courseContent-container {
    padding: 0;
    padding-top: 0px;
    height: 750px;
    min-height: 750px;
    // display: none;
    
    .title {
      font-size: 27px;
      margin-top: 20px;
    }

    .fish-born-time-line {
      display: none;
    }
    
    .time-line-using-for-mobile {
      max-width: 400px;
      min-width: 375px;
      display: flex;
      flex-direction: column;
      // background-color: red;
      width: 90%;
      height: 80%;
      position: relative;

      .item {
        position: absolute;

        width: 100%;
        height: 25%;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        .day {
          width: 100px;
          height: 50px;
          display: flex;
          justify-content: center;
          background-color: #28afe4;
          align-items: center;
          // border: #ffffff 2px solid;

          p {
            margin: 0;
            font-size: 20px;
            font-weight: bolder;
            color: #ffffff;
          }
        }

        .content {
          font-size: 17px;
          width: 50%;
          height: 20%;
          text-align: start;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .upper-content {
            padding: 10px;
            top: 0;
            position: absolute;
            width: 50%;
            height: auto;
            border: 3px solid #28afe4;
            font-weight: bold;
          }
          .lower-content {
            font-weight: bold;
            padding: 10px;
            bottom: -25px;
            position: absolute;
            width: 50%;
            height: auto;
            border: 3px solid #28afe4;
          }
        }
      }

      .item:first-child {
        top: 0;
      }

      .item:nth-child(2) {
        top: 39%;
      }

      .item:nth-child(3) {
        top: 78%;
      }

      .line {
        position: absolute;
        top: 19px;
        left: 135px;
        height: 100%;
        width: 7px;
        background-color: #28afe4;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 50px;

        .ellipse {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background-color: #28afe4;
        }
      }
    }

    .backgroundCouresContent {
      // display: none;

      .ellipse {
        background: radial-gradient(
          circle at 50% 30%,
          rgb(255, 255, 255) 0%,
          rgb(149, 206, 228) 70%
        );
      }

      .ellipse:nth-child(2) {
        top: 15%;
      }

      .ellipse:nth-child(4) {
        bottom: 30%;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .courseContent-container {
    // display: none;
    .title {
      font-size: 30px;
    }
    .fish-born-time-line {
      // display: none;
      margin-top: 0;
      width: 90%;

      .skeleton {
        .fish-tail {
          width: 50px;
          height: 120px;
        }

        .fish-back-born {
          width: calc(100% - 50px - 100px);
          border-bottom: 5px solid;
        }
        .fish-head {
          width: 100px;
          height: 100px;
        }
      }
      .content-couse {
        margin-left: 50px;
        width: calc(100% - 100px - 50px);
        .item {
          .upper-content,
          .lower-content {
            .desc {
              width: 120px;
              height: 100px;
              font-size: 13px;
              padding: 5px;
            }
            .line {
              border-bottom: 5px solid #d4d4d4;
            }
          }
          .day {
          }
        }
      }
    }
    .backgroundCouresContent {
      .ellipse:nth-child(4) {
        bottom: 50px;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199.98px) {
  .courseContent-container {
    // display: none;
    .title {
      font-size: 30px;
    }
    .fish-born-time-line {
      // display: none;
      margin-top: 0;
      width: 90%;

      .skeleton {
        .fish-tail {
          width: 150px;
          height: 200px;
        }

        .fish-back-born {
          width: calc(100% - 50px - 100px);
          border-bottom: 5px solid;
        }
        .fish-head {
          width: 200px;
          height: 200px;
        }
      }
      .content-couse {
        margin-left: 50px;
        width: calc(100% - 200px);
        .item {
          .upper-content,
          .lower-content {
            .desc {
              min-width: 120px;
              max-height: 100px;
              font-size: 15px;
              padding: 5px;
            }
            .line {
              border-bottom: 5px solid #d4d4d4;
            }
          }
          .day {
          }
        }
      }
    }
    .backgroundCouresContent {
      .ellipse:nth-child(4) {
        bottom: 50px;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1639.98px) {
  .courseContent-container {
    // display: none;
    .title {
      font-size: 35px;
    }

    .fish-born-time-line {
      .content-couse {
        .item {
          box-sizing: border-box;

          .upper-content,
          .lower-content {
            .desc {
              font-size: 20px;
              width: calc(100% - 30px);
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
}

// Extra extra large devices (larger desktops, 1400px and up)

@media (min-width: 1640px) and (max-width: 1999.98px) {
  .courseContent-container {
    // display: none;
  }
}

@media (min-width: 2000px) {
  .courseContent-container {
    max-width: 1980px;
    // display: none;
  }
}
