.highlightTextContainer {
  font-size: 20px;
  color: rgb(0, 0, 0);

  span {
    color: #28afe4;
    font-weight: bold;
  }
}

@media (max-width: 575.98px) {
  .highlightTextContainer {
    // display: none;
    p {
      font-size: 14px;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

// Extra extra large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
