.targetAudienceContainer {
  width: 80%;
  height: 80%;
  margin-top: 70px;

  position: relative;
  background-color: #8cabb73a;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .leftSide {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-left: 50px;
    padding-top: 50px;

    .title {
      font-size: 35px;
      color: #28afe4;
      text-align: start;
    }
    .audience {
      width: 70%;

      .item {
        width: 100%;
        height: 40px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: start;

        .icon {
          width: 20px;
          height: 20px;
        }

        p {
          height: 100%;
          width: calc(100% - 60px);
          font-size: 20px;
          color: rgb(0, 0, 0);
          text-align: start;
          margin: 0px;
        }
      }
    }

    .schedule {
      .icon {
        width: 30px;
        height: 30px;
        fill: #000000;
      }

      .duration {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 30px;
      }

      .fomat {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 30px;
      }
    }
  }
  .rightSide {
    width: 700px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: end;
    position: relative;

    .img {
      border-radius: 10px;
      border: 10px solid #28afe4;
      object-fit: cover;
      position: absolute;
    }

    .img:first-child {
      top: -25px;
      right: 50px;
      width: 475px;
      height: 214px;
      z-index: 3;
    }

    .img:nth-child(2) {
      top: 28%;
      right: 30%;
      z-index: 2;
      width: 439px;
      height: 250px;
    }

    .img:nth-child(3) {
      width: 350px;
      height: 253px;
      bottom: -25px;
      right: 20%;
      z-index: 1;
    }

    .backgroundImg {
      position: absolute;
      top: 30%;
      right: 15%;

      padding: 100px;
      background: transparent; /* Đảm bảo background trong suốt */
    }

    .backgroundImg::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-radius: inherit;
      background: linear-gradient(
        0deg,
        rgba(114, 20, 158, 1) 0%,
        rgba(51, 154, 168, 1) 53%,
        rgba(144, 255, 255, 1) 83%
      ); /* Thay đổi màu gradient tại đây */
    }

    .backgroundImg::after {
      content: "";
      position: absolute;
      top: 2px; /* Điều chỉnh độ dày của border */
      right: 2px;
      bottom: 2px;
      left: 2px;
      z-index: -1;
      background: white; /* Hoặc màu nền bạn muốn */
      border-radius: inherit;
    }
  }

  .background {
    z-index: -1;
  }
}

@media (max-width: 575.98px) {
  .targetAudienceContainer {
    // display: none;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 750px;
    margin-top: 0px;

    .leftSide {
      width: 100%;
      height: auto;
      display: flex;
      padding: 20px;
      box-sizing: border-box;

      .audience {
        width: 100%;
        .title {
          font-size: 20px;
          margin-top: 0;
        }

        .item {
          margin-bottom: 10px;
          .icon {
            width: 10px;
            height: 10px;
            margin-top: 5px;
          }

          p {
            font-size: 14px;
            width: calc(100% - 20px);
          }
        }
      }
      .schedule {
        width: 100%;
        .title {
          font-size: 20px;
        }
        .duration,
        .fomat {
          display: flex;
          justify-content: start;
          align-items: start;
          gap: 10px;
          // margin-top: 20px;
          .icon {
            width: 20px;
            height: 20px;
          }
          .highlightTextContainer {
            text-align: left;
            font-size: 14px;
            margin: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
    .rightSide {
      width: 300px;
      height: 270px;
      margin-top: 0px;

      .img {
        border: 5px solid #28afe4;
        position: absolute;
      }

      .img:first-child {
        width: 60%;
        height: auto;
        right: 20px;
        top: 0;
        margin: 0;
      }
      .img:nth-child(2) {
        width: 50%;
        height: auto;
        left: 20px;
        top: 22%;
        margin: 0;
      }
      .img:nth-child(3) {
        width: 50%;
        margin: 0;
        height: auto;
        top: 50%;
        right: 20%;
      }
      .backgroundImg {
        position: absolute;
        top: 10%;
        left: 21%;
        margin: 0;
        border-radius: 20px;
      }
    }
    .background {
      z-index: -1;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      .ellipse {
        width: 200px;
        height: 200px;
        filter: blur(90px);
      }

      .purple {
        top: 30%;
        right: 0;
        left: auto;
      }

      .yellow {
        top: 20px;
        left: 0;
      }

      .bg {
        top: auto;
        right: 0;
        margin: 0;
        bottom: 0;
      }

      .rectangle {
        width: 200px;
        height: 200px;
        filter: blur(40px);
      }
      // display: none;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .targetAudienceContainer {
    // display: none;
    flex-direction: column;
    padding: 50px;

    .leftSide {
      width: 100%;
      padding: 0;

      .audience {
        width: 100%;
        .title {
          font-size: 20px;
          margin-top: 0;
        }
        .item {
          margin-bottom: 5px;

          .icon {
            width: 15px;
            height: 15px;
          }
          p {
            font-size: 15px;
            width: calc(100% - 40px);
          }
        }
      }

      .schedule {
        .title {
          font-size: 20px;
        }

        .duration,
        .fomat {
          gap: 20px;
          .icon {
            width: 20px;
            height: 20px;
          }
          .highlightTextContainer {
            font-size: 15px;
          }
        }
      }
    }

    .rightSide {
      width: 500px;
      height: 400px;
      margin-top: 20px;
      // background-color: red;

      .img:first-child {
        width: 250px;
        height: 100px;
        top: 0;
      }

      .img:nth-child(2) {
        top: 23%;
        width: 250px;
        height: 150px;
      }
      .img:nth-child(3) {
        width: 200px;
        height: 150px;
        bottom: 0;
      }

      .backgroundImg {
        width: 100px;
        height: 100px;
        top: 10%;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 899.98px) {
  .targetAudienceContainer {
    // display: none;
    flex-direction: column;
    padding: 50px;

    .leftSide {
      width: 100%;
      padding: 0;

      .audience {
        width: 100%;
        .title {
          font-size: 20px;
          margin-top: 0;
        }
        .item {
          margin-bottom: 5px;

          .icon {
            width: 15px;
            height: 15px;
          }
          p {
            font-size: 15px;
            width: calc(100% - 40px);
          }
        }
      }

      .schedule {
        .title {
          font-size: 20px;
        }

        .duration,
        .fomat {
          gap: 20px;
          .icon {
            width: 20px;
            height: 20px;
          }
          .highlightTextContainer {
            font-size: 15px;
          }
        }
      }
    }

    .rightSide {
      width: 500px;
      height: 400px;
      margin-top: 20px;
      // background-color: red;

      .img:first-child {
        width: 250px;
        height: 100px;
        top: 0;
      }

      .img:nth-child(2) {
        top: 23%;
        width: 250px;
        height: 150px;
      }
      .img:nth-child(3) {
        width: 200px;
        height: 150px;
        bottom: 0;
      }

      .backgroundImg {
        width: 100px;
        height: 100px;
        top: 10%;
      }
    }
  }
}

// // Medium devices (tablets, 768px and up)
@media (min-width: 900px) and (max-width: 1199.98px) {
  .targetAudienceContainer {
    // display: none;
    align-items: start;

    .leftSide {
      padding: 50px 0 50px 50px;
      .audience {
        width: 90%;
        .title {
          font-size: 20px;
        }

        .item {
          margin-bottom: 15px;
          .icon {
            margin-top: 5px;
            width: 10px;
            height: 10px;
          }

          p {
            width: calc(100% - 20px);
            font-size: 14px;
          }
        }
      }

      .schedule {
        width: 90%;
        .title {
          margin-top: 30px;
          font-size: 20px;
        }

        .duration,
        .fomat {
          align-items: start;
          gap: 10px;
          .icon {
            width: 20px;
            height: 20px;
          }
          p {
            font-size: 14px;
            margin-top: 0;
            text-align: left;
          }
        }
      }
    }

    .rightSide {
      min-width: 390px;
      max-width: 390px;
      height: 400px;
      display: flex;
      justify-content: start;
      margin: auto;
      box-sizing: border-box;

      .img {
        border: #28afe4 solid 5px;
      }

      .img:first-child {
        width: 300px;
        height: 150px;
        top: 0;
        right: 10px;
      }
      .img:nth-child(2) {
        width: 300px;
        height: 150px;
        top: 130px;
        left: 0;
      }

      .img:nth-child(3) {
        width: 200px;
        height: 130px;
        top: 260px;
        left: 30%;
      }
      .backgroundImg {
        // display: none;
        right: 40px;
        top: 50px;
      }
    }

    .background {
      .ellipse {
        width: 200px;
        height: 200px;
        filter: blur(70px);
      }

      .purple {
        left: 5%;
      }
      .yellow {
        top: 60%;
      }

      .rectangle {
        filter: blur(50px);
      }
    }
  }
}

// // Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .targetAudienceContainer {
    // display: none;
    min-height: 700px;
    width: 90%;
    align-items: start;

    .leftSide {
      margin-left: 2%;
      .audience {
        width: 90%;
        .title {
        }
      }
    }
    .rightSide {
      min-width: 500px;
      max-width: 500px;
      height: 500px;
      display: flex;
      justify-content: start;
      margin: auto;
      box-sizing: border-box;

      .img {
        border: #28afe4 solid 5px;
      }

      .img:first-child {
        width: 400px;
        height: 200px;
        top: 0;
        right: 10px;
      }
      .img:nth-child(2) {
        width: 300px;
        height: 150px;
        top: 190px;
        left: 0;
      }

      .img:nth-child(3) {
        width: 250px;
        height: 200px;
        top: 300px;
        left: 30%;
      }
      .backgroundImg {
        // display: none;
        right: 40px;
        top: 150px;
      }
    }
  }
}

// // Extra extra large devices (larger desktops, 1400px and up)
@media (min-width: 1500px) and (max-width: 1599.98px) {
  .targetAudienceContainer {
    // display: none;
    min-height: 700px;
    width: 90%;
    align-items: start;

    .leftSide {
      margin-left: 2%;
      .audience {
        width: 90%;
        .title {
        }
      }
    }

    .rightSide {
      min-width: 600px;
      max-width: 600px;
      height: 550px;
      display: flex;
      justify-content: start;
      margin: auto;
      box-sizing: border-box;
      // background-color: red;
      .img:first-child {
        width: 390px;
        height: 180px;
        top: 0;
        right: 20px;
      }

      .img:nth-child(2) {
        width: 390px;
        height: 200px;
      }

      .img:nth-child(3) {
        width: 290px;
        height: 200px;
        bottom: 0;
      }

      .backgroundImg {
        margin: 0;
        right: 70px;
        top: 150px;
      }
    }
  }
}
@media (min-width: 1600px) and (max-width: 1999.98px) {
  .targetAudienceContainer {
    // display: none;

    align-items: start;
    .leftSide {
      margin-bottom: 50px;
      .audience {
        width: 90%;
      }
    }

    .rightSide {
      min-width: 600px;
      max-width: 600px;
      height: 550px;
      display: flex;
      justify-content: start;
      margin: auto;
      box-sizing: border-box;
      // background-color: red;
      .img:first-child {
        width: 390px;
        height: 180px;
        top: 0;
        right: 20px;
      }

      .img:nth-child(2) {
        width: 390px;
        height: 200px;
      }

      .img:nth-child(3) {
        width: 290px;
        height: 200px;
        bottom: 0;
      }

      .backgroundImg {
        margin: 0;
        right: 70px;
        top: 150px;
      }
    }
  }
}

@media (min-width: 2000px) {
  .targetAudienceContainer {
    // display: none;
    max-width: 1980px;
    max-height: 1040px;

    align-items: start;
    .leftSide {
      margin-bottom: 50px;
    }

    .rightSide {
      min-width: 600px;
      max-width: 600px;
      height: 550px;
      display: flex;
      justify-content: start;
      margin: auto;
      box-sizing: border-box;
      // background-color: red;
      .img:first-child {
        width: 390px;
        height: 180px;
        top: 0;
        right: 20px;
      }

      .img:nth-child(2) {
        width: 390px;
        height: 200px;
      }

      .img:nth-child(3) {
        width: 290px;
        height: 200px;
        bottom: 0;
      }

      .backgroundImg {
        margin: 0;
        right: 70px;
        top: 150px;
      }
    }
  }
}
