.btnShare {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  border: 3px solid #28afe4;
  background-color: transparent;

  .icon {
    width: 30px;
    height: 30px;
    fill: #28afe4;
  }

  .text {
    color: #28afe4;
    font-size: 17px;
    font-weight: 600;
  }
}
