.contact-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 70px;
  // .form-container {
  //   width: 25%;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: start;
  //   justify-content: start;

  //   .title {
  //     width: 100%;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;
  //     align-items: center;
  //     margin: 0;

  //     // .icon {
  //     //   width: 30px;
  //     //   height: 30px;
  //     //   fill: #28afe4;
  //     // }

  //     h1 {
  //       width: calc(100% - 60px);
  //       font-size: 30px;
  //       color: #28afe4;
  //       margin: 0;
  //     }

  //     .button {
  //     }
  //   }
  // }

  // .line {
  //   width: 5px;
  //   height: 88%;
  //   background-color: #e6e6e6;
  //   border-radius: 100px;
  //   margin-left: 100px;
  //   margin-right: 100px;
  // }

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 350px;
    width: 600px;
    background-color: #ace5fb49;
    border-radius: 50px;

    h1 {
      font-size: 30px;
      width: 90%;
      color: #28afe4;
    }

    .button {
      height: 70px;
      width: 350px;
      text-decoration: none;
      border-radius: 10px;
      background-color: #28afe4;
      font-size: 30px;
      font-weight: bold;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .supportInfor {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .icon {
        width: 30px;
        height: 30px;
        fill: #28afe4;
      }
      h1 {
        font-size: 30px;
        color: #28afe4;
        width: calc(100% - 60px);
        margin: 0;
        text-align: start;
      }
    }

    .telemaketerCard {
      height: 222px;
      width: 650px;
      display: flex;
      justify-content: start;
      align-items: center;
      background-color: #fefefe;
      border-radius: 10px;
      margin-top: 50px;
      gap: 70px;
      box-shadow: 0 4px 8px rgb(0, 0, 0, 0.25);
      img {
        margin-left: 30px;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        border: solid 5px #28afe4;
        object-fit: cover;
        object-position: 0px -10px;
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          font-size: 30px;
        }

        .bottom-side {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          .phone,
          .email {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;

            .icon {
              width: 25px;
              height: 25px;
              fill: #28afe4;
            }

            a {
              font-size: 20px;
              color: #666666;
              text-decoration: none;
            }
          }
        }
      }
    }

    .companyAddress {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      gap: 20px;
      width: 100%;
      .title {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        text-align: center;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        li {
          width: 100%;
          display: flex;
          align-items: start;
          justify-content: space-between;
          .icon {
            fill: #28afe4;
          }

          a {
            text-decoration: none;
            color: #666666;
            width: calc(100% - 40px);
            font-size: 17px;
            font-style: italic;
            font-weight: 400;
            margin: 0;
            text-align: start;
            line-height: 40px;
          }
        }
      }
    }

    .socialMediaContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      margin-top: 40px;
      .title {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
      }
      .socialMedia {
        display: flex;
        gap: 50px;
        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .contact-container {
    padding: 0;
    .title-container {
      border-radius: 10px;
      width: 300px;
      height: 150px;

      h1 {
        font-size: 17px;
      }

      .button {
        height: 40px;
        width: 150px;
        font-size: 13px;
      }
    }

    .supportInfor {
      width: 300px;
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .contact-container {
    .title-container {
      border-radius: 10px;
      width: 400px;
      height: 200px;

      h1 {
        font-size: 20px;
      }

      .button {
        height: 50px;
        width: 200px;
        font-size: 15px;
      }
    }

    .supportInfor {
      width: 400px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .contact-container {
    padding: 50px;
    padding-top: 100px;
    justify-content: space-between;
    .form-container {
      width: 40%;
      .title {
        .icon {
          width: 20px;
          height: 20px;
        }
        h1 {
          font-size: 20px;
        }
      }
    }

    .line {
      display: none;
    }

    .supportInfor {
      width: 50%;

      .title {
        align-items: start;
        .icon {
          margin-top: 5px;
          width: 20px;
          height: 20px;
        }
        h1 {
          font-size: 20px;
        }
      }

      .telemaketerCard {
        width: 400px;
        gap: 0;

        img {
          width: 150px;
          height: 150px;
        }

        .info {
          width: calc(100% - 150px - 30px);
          height: 150px;
          padding: 20px;
          box-sizing: border-box;
          h1 {
            height: 50%;
            margin: 0;
            font-size: 20px;
          }

          .bottom-side {
            height: 50%;
            display: flex;
            align-items: start;
            justify-content: space-between;
            .phone,
            .email {
              gap: 10px;
              .icon {
                width: 15px;
                height: 15px;
              }
              a {
                margin: 0;
                font-size: 15px;
              }
            }
          }
        }
      }
      .companyAddress {
        width: 100%;
      }
      .socialMediaContainer {
        .title {
          text-align: left;
        }
      }
    }
  }
}

// @media (min-width: 1200px) and (max-width: 1439.98px) {
//   .contact-container {
//     justify-content: space-evenly;

//     .line {
//       display: none;
//     }

//     .supportInfor {
//       width: 40%;

//       .title {
//         .icon {
//           margin-top: 4px;
//           width: 25px;
//           height: 25px;
//         }
//         h1 {
//           font-size: 25px;
//         }
//       }

//       .telemaketerCard {
//         width: 550px;
//         min-height: auto;
//         gap: 0;
//         img {
//           width: 170px;
//           height: 170px;
//         }

//         .info {
//           height: 170px;
//           justify-content: space-between;
//           width: calc(100% - 170px - 30px - 20px);

//           h1 {
//             margin: 0;
//             font-size: 30px;
//             height: 40px;
//           }
//           .bottom-side {
//             height: calc(100% - 40px);
//             display: flex;
//             align-items: start;
//             justify-content: space-evenly;

//             .phone,
//             .email {
//               a {
//                 margin: 0px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

// @media (min-width: 1440px) and (max-width: 1999.98px) {
//   .contact-container {
//     justify-content: space-evenly;
//     .form-container {
//       width: 30%;

//       .title {
//         .icon {
//           width: 20px;
//           height: 20px;
//           margin-top: 5px;
//         }
//         h1 {
//           font-size: 25px;
//         }
//       }
//     }

//     .line {
//       display: none;
//     }

//     .supportInfor {
//       width: 40%;

//       .title {
//         .icon {
//           margin-top: 4px;
//           width: 25px;
//           height: 25px;
//         }
//         h1 {
//           font-size: 25px;
//         }
//       }

//       .telemaketerCard {
//         width: 650px;
//         min-height: auto;
//         gap: 0;
//         img {
//           width: 170px;
//           height: 170px;
//         }

//         .info {
//           height: 170px;
//           justify-content: space-between;
//           width: calc(100% - 170px - 30px - 20px);

//           h1 {
//             margin: 0;
//             font-size: 30px;
//             height: 40px;
//           }
//           .bottom-side {
//             height: calc(100% - 40px);
//             display: flex;
//             align-items: start;
//             justify-content: space-evenly;

//             .phone,
//             .email {
//               a {
//                 margin: 0px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

// @media (min-width: 2000px) {
//   .contact-container {
//     max-width: 1980px;
//     justify-content: space-evenly;
//     .form-container {
//       width: 30%;

//       .title {
//         .icon {
//           width: 20px;
//           height: 20px;
//           margin-top: 5px;
//         }
//         h1 {
//           font-size: 25px;
//         }
//       }
//     }

//     .line {
//       display: none;
//     }

//     .supportInfor {
//       width: 40%;

//       .title {
//         .icon {
//           margin-top: 4px;
//           width: 25px;
//           height: 25px;
//         }
//         h1 {
//           font-size: 25px;
//         }
//       }

//       .telemaketerCard {
//         width: 650px;
//         min-height: auto;
//         gap: 0;
//         img {
//           width: 170px;
//           height: 170px;
//         }

//         .info {
//           height: 170px;
//           justify-content: space-between;
//           width: calc(100% - 170px - 30px - 20px);

//           h1 {
//             margin: 0;
//             font-size: 30px;
//             height: 40px;
//           }
//           .bottom-side {
//             height: calc(100% - 40px);
//             display: flex;
//             align-items: start;
//             justify-content: space-evenly;

//             .phone,
//             .email {
//               a {
//                 margin: 0px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
