.voucherContainer {
  // width: 70%;
  height: auto;

  background-color: #ace5fb75;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  padding: 50px;
  gap: 20px;

  .highlightTextContainer {
    font-weight: bold;
    color: 777777;
    margin: 0;

    .highlight {
      background-image: linear-gradient(
        90deg,
        rgba(255, 0, 0, 1) 0%,
        rgb(190, 172, 3) 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  .highlightTextContainer:first-child {
    font-size: 30px;
  }
  .highlightTextContainer:nth-child(2) {
    font-size: 20px;
  }

  .offer {
    .offerItem {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 20px;

      p {
        font-size: 20px;
        font-weight: bold;
        margin-left: 10px;
      }

      .icon {
        width: 30px;
        height: 30px;
        fill: #28afe4;
      }
    }
  }

  .btn-enroll-now {
    text-decoration: none;
    width: 200px;
    height: 50px;
    border-radius: 10px;
    background-color: #28afe4;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 575.98px) {
  .voucherContainer {
    padding: 20px;
    margin-top: 0px;
    padding-top: 50px;
    padding-bottom: 50px;


    .highlightTextContainer:first-child {
      font-size: 20px;
    }

    .highlightTextContainer:nth-child(2) {
      width: 85%;
      font-size: 14px;
    }

    .offer {
      .offerItem {
        display: flex;
        align-items: start;
        justify-content: start;
        gap: 20px;

        .icon {
          width: 20px;
          height: 20px;
        }

        p {
          margin: 0;
          font-size: 13px;
          text-align: left;
          margin-bottom: 15px;
        }
      }
    }
    .btn-enroll-now {
      height: 40px;
      display: flex;
      font-size: 15px;
      justify-content: center;
      align-items: center;
    }
    // display: none;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .voucherContainer {
    padding: 20px;
    margin-top: 80px;
    // display: none;
    .highlightTextContainer:first-child {
      font-size: 20px;
    }

    .highlightTextContainer:nth-child(2) {
      width: 85%;
      font-size: 15px;
    }
    .offer {
      .offerItem {
        .icon {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 15px;
        }
      }
    }
    .btn-enroll-now {
      height: 40px;
      font-size: 15px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199.98px) {
  .voucherContainer {
    // display: none;
    margin: 50px 0 50px 0;
    padding: 20px;

    .highlightTextContainer:first-child {
      font-size: 20px;
    }

    .highlightTextContainer:nth-child(2) {
      font-size: 15px;
    }

    .offer {
      .offerItem {
        .icon {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 15px;
        }
      }
    }

    .btn-enroll-now {
      height: 40px;
      font-size: 15px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1439.98px) {
  .voucherContainer {
    // display: none;
    margin: 50px 0 00px 0;
    padding: 20px;

    .highlightTextContainer:first-child {
      font-size: 20px;
    }

    .highlightTextContainer:nth-child(2) {
      font-size: 15px;
    }

    .offer {
      .offerItem {
        .icon {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 15px;
        }
      }
    }

    .btn-enroll-now {
      height: 40px;
      font-size: 15px;
    }
  }
}

// Extra extra large devices (larger desktops, 1400px and up)
@media (min-width: 1440px) and (max-width: 1999.98px) {
  .voucherContainer {
    // display: none;
    margin-top: 50px;
  }
}

@media (min-width: 2000px) {
  .voucherContainer {
    max-width: 1980px;
    margin: auto;
    margin-top: 100px;
    // display: none;
  }
}
